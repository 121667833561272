<template>
  <div>
    <div
      class="modal fade"
      id="modal-cierre-cambio-ventana"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambiar ventana</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <div>
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento actual</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoDescargue"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      disabled="disabled"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-12">
                    <label>Ventana enturnamiento (cambio)</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamientoNueva"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      :options="listasForms.ventanasEnturneDescargue"
                      :filterable="false"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-dark"
                v-show="
                  !$v.ventanaEnturnamientoNueva.$invalid &&
                    $store.getters.can(
                      'hidrocarburos.cierreGuias.cambiarVentana'
                    )
                "
                @click="cambiarVentanaDescargue()"
              >
                Cambiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CierreCambioVentana",
  components: {
    vSelect,
  },
  data() {
    return {
      ventanaEnturnamientoDescargue: null,
      viaje: null,
      accionVentana: 0,
      ventana: 0,
      ventanaEnturnamientoNueva: [],
      viaje_id: null,
      ventana_id: null,
      sitio_id: null,
      sitio_type: null,
      form: {
        ventana_enturnamiento_id: null,
      },
      listasForms: {
        ventanasEnturneDescargue: [],
        ventanasEnturne2: [],
      },
    };
  },
  validations: {
    ventanaEnturnamientoNueva: {
      required,
    },
  },
  methods: {
    buscarVentanasEnturneDescargue(sitio_id) {
      let me = this;
      axios
        .get("api/hidrocarburos/cierreGuias/listaVentanas", {
          params: {
            sitio_id: sitio_id,
            sitio_type: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturneDescargue = respuesta;
          let itemELiminar = 0;
          let i = 0;
          if (me.ventanaEnturnamientoDescargue) {
            me.listasForms.ventanasEnturneDescargue.forEach((element) => {
              if (element.id == me.ventanaEnturnamientoDescargue.id) {
                itemELiminar = i;
              }
              i++;
            });
            me.listasForms.ventanasEnturne2 = me.listasForms.ventanasEnturneDescargue.splice(
              itemELiminar,
              1
            );
          }
        })

        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenar_modal_cambio_ventana(viaje, cierre_id, observacion) {
      this.viaje = viaje;
      this.form.id = viaje.id;
      this.form.cierre_id = cierre_id;
      this.form.observacion = observacion;
      this.ventanaEnturnamientoDescargue = null;
      if (viaje.ventana_enturne_descargue_id != null) {
        this.ventanaEnturnamientoDescargue = viaje.ventana_descargue;
      } else {
        this.ventanaEnturnamientoDescargue = null;
      }
      this.buscarVentanasEnturneDescargue(viaje.sitio_descargue_id);
      this.ventanaEnturnamientoNueva = [];
    },

    cambiarVentanaDescargue() {
      if (!this.$v.ventanaEnturnamientoNueva.$invalid) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamientoNueva.id;
        axios({
          method: "PUT",
          url: "api/hidrocarburos/cierreGuias/cambiarVentanaDescargue",
          data: this.form,
        }).then(() => {
          this.$parent.cargarCierre();
          this.$refs.closeModal1.click();
          this.form = {};
          this.ventanaEnturnamientoNueva = [];

          this.$swal({
            icon: "success",
            title: "Se actualizó la ventana de descargue exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },
  },
};
</script>
